<template>
  <div class="form">
    <div class="wrapper illustration illustration_form">
      <Backlink title="detail" service="mortgage" backlink="/mortgage/client" />
      <div class="content">
        <div class="content__container">
          <p class="promo promo_blocks2">{{ "info_title" | localize }}</p>
          <form @submit.prevent>
            <div class="form__group">
              <v-autocomplete
                v-model="city"
                :items="cities"
                @change="checkForm($v.city)"
                @blur="nextElementfocus('city', 'object')"
                @keyup.enter="$event.target.blur()"
                :class="{ invalid: $v.city.$dirty && !$v.city.required }"
                item-text="name"
                item-value="value"
                cache-items
                :label="'realty_city' | localize"
                :placeholder="'select' | localize"
                append-icon="icon-arrow-down"
                ref="city"
                outlined
              ></v-autocomplete>

              <v-select
                v-model="object"
                @change="checkForm($v.object); nextElementfocus('object', 'price')"
                @blur="nextElementfocus('object', 'price')"
                @keyup.enter="$event.target.blur()"
                :items="getList('s2p4_select_1_value_', 4)"
                :class="{ invalid: $v.object.$dirty && !$v.object.required }"
                item-text="name"
                item-value="value"
                :label="'info_realty_type' | localize"
                :placeholder="'select' | localize"
                :menu-props="{ offsetY: true }"
                append-icon="icon-arrow-down"
                ref="object"
                outlined
              ></v-select>
            </div>

            <div class="form__group">
              <v-text-field
                v-model="price"
                class="input_number"
                inputmode="numeric"
                pattern="[0-9]*"
                @blur="nextElementfocus('price', 'capital')"
                @keyup.enter="$event.target.blur()"
                @keypress="onKeyPressHandler"
                @input="countPencent"
                @keyup="onKeyUpPriceHandler('price', price)"
                :class="{ invalid: $v.price.$dirty && !$v.price.required }"
                :hint="($v.price.$dirty && !$v.price.required ? 'form_reqiered' : 'empty') | localize"
                :label="'s2p4_text_2' | localize"
                :placeholder="'s2p4_placeholder_1' | localize"
                append-icon="icon-₪"
                ref="price"
                outlined
              ></v-text-field>

              <div class="form__element">
                <v-text-field
                  v-model="capital"
                  class="input_number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  @blur="$v.capital.$touch()"
                  @keypress="onKeyPressHandler"
                  @input="countPencent"
                  @keyup="onKeyUpPriceHandler('capital', capital)"
                  :class="{ invalid: $v.capital.$dirty && !$v.capital.required }"
                  :hint=" ($v.capital.$dirty && !$v.capital.required ? 'form_reqiered' : 'empty') | localize"
                  :label="'s2p4_text_4' | localize"
                  :placeholder="'example_text' | localize('1 000 000')"
                  :message="percent"
                  append-icon="icon-₪"
                  ref="capital"
                  outlined
                ></v-text-field>
                <div class="slider--ticks slider--ticks_mt" v-if="price && capital && percent">
                  <span>{{ percent.toLocaleString($store.state.lang) }}{{ 's2p4_text_7' | localize }}</span>
                </div>
                </div>
            </div>

            <div v-if="price && capital">
              <div class="error__container" v-if="amount < 50000">
                <p class="error">{{ 'mortgage_error_min_sum' | localize }} <span class="icon-₪"></span><br>{{ 'mortgage_error_enter' | localize }}</p>
              </div>

              <div class="error__container" v-else-if="financing > 75">
                <p class="error">{{ 'mortgage_error_percent' | localize }}<br>{{ 'mortgage_error_enter' | localize }}</p>
              </div>

              <div class="info info_large" v-else>
                <div>{{ 'mortgage_sum' | localize }}: {{ amount.toLocaleString() }} <span class="icon-₪"></span></div>
                <div>{{ 'fin_percent' | localize }}: {{ financing }} %</div>
              </div>
            </div>

            <div class="error__container error__container_button">
              <p class="error" v-if="error">{{ error | localize }}</p>
              <p class="error" v-if="!success && check_form && (!price || !capital || !city || !object)">{{ 'field_required' | localize }}</p>
            </div>

            <button
              type="button"
              @click="submitHandler"
              class="button button__form"
              :class="{ button__form_disabled: !success }"
            >
              {{ "continue" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import Backlink from '@/components/base/Backlink.vue'
import { formMixin } from '@/mixins/formMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'
import { saveMixin } from '@/mixins/saveMixin.js'

export default {
  name: 'Mortgage-info',
  components: { Backlink },
  mixins: [formMixin, commonMixin, numberMixin, saveMixin],
  data: () => ({
    cities: [],
    city: null,
    price: null,
    capital: null,
    object: null,

    percent: null,
    amount: null,
    financing: null,
    error: null
  }),
  validations: {
    city: { required },
    price: { required },
    capital: { required },
    object: { required }
  },
  mounted () {
    const localeName = 'name_' + this.$store.state.lang
    this.axios
      .get('cities?sort=population&projection=id,' + localeName)
      .then((response) => {
        this.cities = response.data.map((e) => ({
          name: e[localeName],
          value: e.id
        }))
      })
      .catch((e) => {
        this.error = 'server_error'
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
  },
  methods: {
    submitHandler () {
      this.check_form = true
      const mortgage = this.$cookies.get('mortgage') || {}
      this.price = this.parseNumber(this.price)
      this.capital = this.parseNumber(this.capital)

      if (this.$v.$invalid) {
        this.$v.$touch()
        this.success = false
        return
      }

      if (!this.success) {
        return
      }

      mortgage.city = this.city
      mortgage.object = this.object
      mortgage.price = parseInt(this.price)
      mortgage.capital = parseInt(this.capital)

      this.$cookies.set('mortgage', mortgage)
      this.saveServiceInfo('mortgage', false, {
        mortgage_city_id: this.city,
        realty_type: this.object,
        mortgage_realty_price: mortgage.price,
        mortgage_capital: mortgage.capital
      })
      this.saveUserService('mortgage', '/mortgage/reg-payment', { amount: this.amount })
    },
    countPencent () {
      if (this.capital && this.price) {
        const capital = this.parseNumber(this.capital)
        const price = this.parseNumber(this.price)
        this.percent = Math.round((capital * 100) / price)
        this.amount = Math.round(price - capital)
        this.financing = Math.round((this.amount * 100) / price)
      }

      this.checkForm()

      if (this.amount < 50000 || this.financing > 75) {
        this.success = false
      }
    }
  },
  created () {
    const mortgage = this.$cookies.get('mortgage') || {}
    this.price = this.formatNumber(mortgage.price || 0)
    this.capital = this.formatNumber(mortgage.capital || 0)
    this.object = mortgage.object || null
    this.city = mortgage.city || null
    this.countPencent()
  }
}
</script>
